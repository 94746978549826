.map-contact {
	padding: 5% 0 5% 0;
	.gmaps {
		width: 100%;
		border: 3px solid #fff;
	}	
	
	.the-address {
		a {
			color: #7e7e7e!important;
		}
		@media (max-width: 768px) {
			text-align: center;
		}
	}
	span.small-cion {
		font-size: 23px;
		font-weight: 600;
		line-height: 80px;
		img {
			height: 19px;
			padding-right: 14px;
			&:nth-of-type(3) {
				width: 36px;
			}
		}
	}
}
	
.social-media-on-map {
	background-color: #E8E8E8;
	background: url('../images/mapa_punkty.svg');
	background-size: 100%;
	background-position: left top;
}	


