*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
-moz-osx-font-smoothing:grayscale;
-webkit-font-smoothing:antialiased;
font-family: 'Montserrat', sans-serif;
font-size:.85em;
color: #888;
background-color: #f5f5f5;
}

ol, ul {
    list-style: circle;
}

img {
  max-width: 100%;
}

h1, h2 {
  font-weight: 600;
}

/* Docelowe style timeline */

.nayma-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.nayma-container::after {
  content: '';
  display: table;
  clear: both;
}

#nayma-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#nayma-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: rgba(255, 255, 255, 0.15);
}
@media only screen and (min-width: 1170px) {
  #nayma-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #nayma-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.nayma-timeline-block {
  position: relative;
  margin: 2em 0;
}
.nayma-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.nayma-timeline-block:first-child {
  margin-top: 0;
}
.nayma-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .nayma-timeline-block {
    margin: 4em 0;
  }
  .nayma-timeline-block:first-child {
    margin-top: 0;
  }
  .nayma-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.nayma-timeline-img {
  background: #FFB200;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 49%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.nayma-timeline-img-01 {
  background: url('../images/1.svg');
}

.nayma-timeline-img-02 {
  background: url('../images/2.svg');
}

.nayma-timeline-img-03 {
  background: url('../images/3.svg');
}

.nayma-timeline-img-04 {
  background: url('../images/4.svg');
}

.nayma-timeline-img-05 {
  background: url('../images/5.svg');
}

.nayma-timeline-img-06 {
  background: url('../images/6.svg');
}

.nayma-timeline-img-07 {
  background: url('../images/7.svg');
}

.nayma-timeline-img-08 {
  background: url('../images/8.svg');
}

@media only screen and (max-width: 1170px) {
.nayma-timeline-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 35px;
	height: 35px;
	border-radius: 49%;
	box-shadow: 0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05);
	background-size: 35px 35px;
  }
}
@media only screen and (min-width: 1170px) {
  .nayma-timeline-img {
	position: absolute;
	top: -36px;
	right: 0;
	width: 80px;
	height: 80px;
	box-shadow: 0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05);
	left: 48%;
	margin-left: -11px;
	margin-top: 30px;
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	background-size: 80px 80px;
  }
}
.nayma-timeline-content {
  background-color: #890011;
  position: relative;
  margin-left: 60px;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 2px 7px rgba(0,0,0,.15);
  border-radius: 30px;
}
.nayma-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.nayma-timeline-content h2 {
  color: #FFB200;
}
.nayma-timeline-content p, .nayma-timeline-content {
  font-size: 12px;
  font-size: 0.7125rem;
}
.nayma-timeline-content .nayma-timeline-content {
  display: inline-block;
}
.nayma-timeline-content p {
  font-weight: 300;
  margin: 1em 0;
  line-height: 1.6;
  color: #fff;
  font-size: 0.95rem;
}
.nayma-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 768px) {
  .nayma-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .nayma-timeline-content p {
    font-size: 16px;
    font-size: .9rem;
  }
}
@media only screen and (min-width: 1170px) {
  .nayma-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .nayma-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .nayma-timeline-block:nth-child(even) .nayma-timeline-content {
    float: right;
  }
  .nayma-timeline-block:nth-child(even) .nayma-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
}

.our-timeline {
	background-color: #AB1929;
	background: url('../images/tlo_glut.svg');
	background-size: 100%;
	padding-bottom: 3%;
	h2 {
		color: #FFB200;		
		padding: 3% 0 2% 0;
		font-size: 18px;
	}
	h6 {
		color: #fff;
		padding: 3% 0 2%;
		font-size: 35px;
		text-align: center;
		font-weight: 600;
	}	
}

	.fala-dol {
		width: 102%;
		img {
			width: 102%;
		}
	}

.dokumenty-container {
	background: url('../images/glutki.svg');
	background-size: 100%;
	margin: 5% 0 5% 0;
	.additional-info-title {
			font-weight: 600;
			line-height: 51px;
			border-bottom: 1px solid #e6e6e6;
			font-size: 12pt;
		&:hover {
			cursor: pointer;
		}
		span {
			background-color: #ab1929;
			padding: 0 9px 0px 11px;
			border-radius: 29px;
			color: #fff;
			font-size: 16px;
			margin-right: 20px;
			font-weight: 800;
		}
	}

}