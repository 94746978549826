.footer {
	background-color: #f2f2f2;
	&.white-bg {
		background-color: #fff;
	}	
	
    .bottom {
        background: url("../images/footer.svg") top center no-repeat;
        padding: 100px 0;
        background-size: cover;

        @media (max-width: 991px) {
            background-position: top right;
            padding: 20px 0;
        }

        .contact-data {
            margin-top: 150px;
            color: $white;

            .contact-data-item {

                @media (max-width: 991px) {
                    margin: 20px 0;
                    text-align: center;
                }

                .footer-menu {
                    list-style: none;

						li {
							a {
								color: #fff!important;
							}
						}

                }
            }
        }
    }

    .copyright {
        background: #67000B;
        color: #fff;
        padding: 33px 0;

        .copy-data {
            display: flex;
            justify-content: space-between;

            p {
                margin-bottom: 0
            }

            .socialmenu {
                display: flex;
                list-style: none;
                margin-bottom: 0;
                
                a {
                    color: $white;
                    padding: 0 10px;
                }
            }
        }
    }
}