body {
    font-family: $font-primary;
    color: $color-secondary;
	.form {
		background-color: #f2f2f2;
		padding-top: 8%;
	}
    form {		
        .section-title {
            color: $color-primary;
            font-family: $font-secondary;
            font-size: 34px;
            font-weight: bold;
            margin-bottom: 65px;
            text-align: center;
        }

        .paragraph {
            font-weight: bold;
            font-size: 23px;
            color: $color-paragraph;
            margin-bottom: 31px;
            display: block;

            .number {
                color: $color-primary;
            }
        }

        label {
            width: 100%;
            font-size: 18px;
            font-weight: bold;
        }

        .form-group {

            input {
                width: 100%;
                border: 1px #C0C0C0 solid;
                padding: 17px;
                border-radius: 5px;

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #C0C0C0;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #C0C0C0;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #C0C0C0;
                }
            }
        }

        .multiple-group {
            padding: 0 15px;
        }
    }
}