body {
	overflow-x: hidden!important;
	max-width: 100%!important;
	background-color: #fff;
}

.header {
	font-family: 'Source Sans Pro', sans-serif;
	position: relative;
	color: #7E7E7E;
	overflow-x: hidden!important;

    .top {
        padding: 1.1rem 0 0 0;
		.justify-content-end {	
			.socials-navi {
				z-index: 10;
					img {
						height: 18px;
					}					
			}
		}
    }

	.menu {
		display: flex;		
		align-items: center;
		margin: 0 auto;
		padding: 10px 0 80px;
		justify-content: space-between;		
		&.menu-main  {
			padding: 0 10% 0 10%;
			margin-bottom: 5%;		
		}
		li:hover {  
			animation: shake 0.5s;
			animation-iteration-count: infinite;
		}

		@keyframes shake {
		  0% { transform: translate(1px, 1px) rotate(0deg); }
		  10% { transform: translate(-1px, -2px) rotate(-1deg); }
		  20% { transform: translate(-3px, 0px) rotate(1deg); }
		  30% { transform: translate(3px, 2px) rotate(0deg); }
		  40% { transform: translate(1px, -1px) rotate(1deg); }
		  50% { transform: translate(-1px, 2px) rotate(-1deg); }
		  60% { transform: translate(-3px, 1px) rotate(0deg); }
		  70% { transform: translate(3px, 1px) rotate(-1deg); }
		  80% { transform: translate(-1px, -1px) rotate(1deg); }
		  90% { transform: translate(1px, 2px) rotate(0deg); }
		  100% { transform: translate(1px, -2px) rotate(-1deg); }
		}
		.logo {
			z-index: 10;
		}	
		.navbar {
			ul {
				li {					
					a {
						text-transform: uppercase;
						font-weight: 800;
						color: #464646;					
						cursor: pointer;
						font-size: 1rem;
						@media (max-width: 992px) {
							font-size: 0.95rem;
							padding: 6px;
						}
					}
				}
			}
		}
	}
	
	.header__bg-svg {
		position: absolute;
		top: -250px;
		right: 0; 
		z-index: -3;
	}

	.video-single {
		.inside-video-single {
		padding: 10%;
			.embed-responsive-item {
				border: 5px solid #cfcfcf;
				background: #fff;
				&.one-of-tree {
				padding: 0;
				}
				@media (max-width: 786px) {
					margin-top: 11px;
				}
			}
			margin: 0 auto;
			.video-container {
				position: relative;
				padding-bottom: 56.25%;
				padding-top: 30px; height: 0; overflow: hidden;
			}
			 
			.video-container iframe,
			.video-container object,
			.video-container embed {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}	
		}
	}
	
	.video-more {
		.top-content {
			padding-bottom: 9%;
			background-color: #dcdcdc;
			margin: 0;
		}
		h3 {
			padding: 5%;
		}
		@media (min-width: 768px) and (max-width: 991px) {
			/* Show 4th slide on md if col-md-4*/
			.carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
				position: absolute;
				top: 0;
				right: -33.3333%;  /*change this with javascript in the future*/
				z-index: -1;
				display: block;
				visibility: visible;
			}
		}
		@media (min-width: 576px) and (max-width: 768px) {
			/* Show 3rd slide on sm if col-sm-6*/
			.carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
				position: absolute;
				top: 0;
				right: -50%;  /*change this with javascript in the future*/
				z-index: -1;
				display: block;
				visibility: visible;
			}
		}
		@media (min-width: 576px) {
			.carousel-item {
				margin-right: 0;
			}
			/* show 2 items */
			.carousel-inner .active + .carousel-item {
				display: block;
			}
			.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
			.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
				transition: none;
			}
			.carousel-inner .carousel-item-next {
				position: relative;
				transform: translate3d(0, 0, 0);
			}
			/* left or forward direction */
			.active.carousel-item-left + .carousel-item-next.carousel-item-left,
			.carousel-item-next.carousel-item-left + .carousel-item,
			.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
				position: relative;
				transform: translate3d(-100%, 0, 0);
				visibility: visible;
			}
			/* farthest right hidden item must be also positioned for animations */
			.carousel-inner .carousel-item-prev.carousel-item-right {
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				display: block;
				visibility: visible;
			}
			/* right or prev direction */
			.active.carousel-item-right + .carousel-item-prev.carousel-item-right,
			.carousel-item-prev.carousel-item-right + .carousel-item,
			.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
				position: relative;
				transform: translate3d(100%, 0, 0);
				visibility: visible;
				display: block;
				visibility: visible;
			}
		}
		/* MD */
		@media (min-width: 768px) {
			/* show 3rd of 3 item slide */
			.carousel-inner .active + .carousel-item + .carousel-item {
				display: block;
			}
			.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
				transition: none;
			}
			.carousel-inner .carousel-item-next {
				position: relative;
				transform: translate3d(0, 0, 0);
			}
			/* left or forward direction */
			.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
				position: relative;
				transform: translate3d(-100%, 0, 0);
				visibility: visible;
			}
			/* right or prev direction */
			.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
				position: relative;
				transform: translate3d(100%, 0, 0);
				visibility: visible;
				display: block;
				visibility: visible;
			}
		}
		/* LG */
		@media (min-width: 991px) {
			/* show 4th item */
			.carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
				display: block;
			}
			.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
				transition: none;
			}
			/* Show 5th slide on lg if col-lg-3 */
			.carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
				position: absolute;
				top: 0;
				right: -25%;  /*change this with javascript in the future*/
				z-index: -1;
				display: block;
				visibility: visible;
			}
			/* left or forward direction */
			.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
				position: relative;
				transform: translate3d(-100%, 0, 0);
				visibility: visible;
			}
			/* right or prev direction //t - previous slide direction last item animation fix */
			.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
				position: relative;
				transform: translate3d(100%, 0, 0);
				visibility: visible;
				display: block;
				visibility: visible;
			}
		}
	
	}

    h1{        
		font-family: Montserrat,sans-serif;
		color: #ab1929;
		font-size: 47pt;
		font-weight: 600;
		z-index: 10;
		text-align: center;
		text-transform: uppercase;
		padding: 6% 0 6% 0;
        @media (max-width: 992px) {
            padding-bottom: 30px;
			font-size: 30pt;
        }
    }	
	
    h2{
        font-family: 'Montserrat', sans-serif;
        color: $color-primary;
        font-size: 1.8rem;
        font-weight: bold;
		z-index: 10;
		padding: 0 0 3% 0;
		&.centerr {
			text-align: center;
			padding: 0 0 4% 0;
		}
        @media (max-width: 992px) {
            padding-bottom: 30px;
			font-size: 1.2rem;
        }
    }
	
	h3{
		text-align: center;
        font-family: 'Montserrat', sans-serif;
        color: $color-primary;
        font-size: 1.8rem;
        font-weight: bold;
		z-index: 10;
        @media (max-width: 992px) {
            padding-bottom: 30px;
			font-size: 1.2rem;
        }		
	}
	
	h4{
		text-align: center;
        font-family: 'Montserrat', sans-serif;
        color: $color-primary;
        font-size: 18pt;
        font-weight: bold;
		z-index: 10;
        @media (max-width: 991px) {
            padding-bottom: 30px;
        }		
	}	
	
	h5{
		text-align: center;
        font-family: 'Montserrat', sans-serif;
        color: #4d4d4d;
        font-size: 18pt;
        font-weight: bold;
		z-index: 10;
		margin-bottom: 5%;
        @media (max-width: 991px) {
            padding-bottom: 30px;
        }		
	}	
	
	.grey-line {
		text-align: center;
        font-family: 'Montserrat', sans-serif;
        color: $color-secondary;
        font-size: 22pt;
        font-weight: bold;
		z-index: 10;
        @media (max-width: 991px) {
            padding-bottom: 30px;
        }	
	}

		.jak-dziala-above {
			position: absolute;
			z-index: 10;
			text-align: center;
			margin: 0 auto;
			padding: 4% 0;
			margin: 0 32%;
			@media (max-width: 992px) {
				margin: 0px 20%;
				padding: 2% 0;
			}
			h2 {
			@media (max-width: 1200px) {
				font-size: 15pt!important;
			}
			}
		}
	
    .banner {
		
        display: flex;
        align-items: center;
        position: relative;
		padding: 0 10% 0 10%;
			.explainer-below {
				font-size: 1.1rem!important;
			}		
        .description {

			.redd {
				color: #ac192a!important;
				&.jak-dziala {
					text-align: center;
				}
			}	
            font-size: 20px;
			@media (max-width: 992px) {		
					font-size: 1rem;			
			}			
			&.the-fifthe-desc {
			@media (max-width: 992px) {				
					font-size: 1.1rem;	
					line-height: 18px;					
			}
			}
			@media (max-width: 992px) {
				.the-desc-small {
					font-size: 1rem;
					line-height: 18px;
				}	

			}
        }

        .image-banner {
            position: absolute;
            top: -40px;
            right: 30px;
			width: 40%;			
			&.thefifth {
			.rotate {
				animation: rotation 8s infinite linear;
			}
			@keyframes rotation {
			  from {
				transform: rotate(0deg);
			  }
			  to {
				transform: rotate(359deg);
			  }
			}				
			@media (max-width: 992px) {
				top: 98%;
			}
			}
			
			&.thesisth {
			
			position: absolute;
			top: -40px;
			right: 310px;
			width: 17%;
			
			.rotate {
				animation: rotation 8s infinite linear;
			}
			@keyframes rotation {
			  from {
				transform: rotate(0deg);
			  }
			  to {
				transform: rotate(359deg);
			  }
			}	

				@media (max-width: 992px) {
					display: none;
				}
			
			}
			
            @media (max-width: 992px) {
                width: 500px;
				position: absolute;
				top: 282px;
				right: 0;
				display: block;
				
            }
			
			@media (max-width: 640px) {
				width: 378px;
				top: 384px;
			}
			
			.rotate {
				animation: rotation 8s infinite linear;
			}
			@keyframes rotation {
			  from {
				transform: rotate(0deg);
			  }
			  to {
				transform: rotate(359deg);
			  }
			}			
			
			.laptop-vid {
				z-index: 10;
			@media (max-width: 640px) {
				width: 100%;
				margin-top: 60px;
			}	
				&.rotates {
				@media (max-width: 640px) {
					width: 100%;
					margin-top: 0;
				}				
				}
			}

			.play-vid {
				width: 70px;
				z-index: 11;
				right: 48%;
				position: absolute;
				top: 70%;
				
				&.rotates {
					width: 190px;
					z-index: 11;
					right: 28%;
					position: absolute;
					top: 60%;				
					animation: rotation 8s infinite linear;	
					@keyframes rotation {
					  from {
						transform: rotate(0deg);
					  } 
					  to {
						transform: rotate(359deg);
					  }
					}
					@media (max-width: 1600px) {
						width: 150px;
					}					
					@media (max-width: 992px) {
						width: 22%;
					}
				}
				
				&.main-play {
					width: 79px;
					z-index: 11;
					right: 45%;
					position: absolute;
					top: 70%;				
					@media (max-width: 992px) {
						width: 12%;
						right: 46%;
						top: 65%;
					}
				}
				
				&.clicked {
				
					width: 193px;
					z-index: 11;
					right: 1%;
					position: absolute;
					top: 22%;				
				
				@media (max-width: 1360px) {
				width: 163px;
				z-index: 11;
				right: -7%;
				position: absolute;
				top: 14%;
				}
				}
				
				@media (max-width: 991px) {				
					width: 39px;
					z-index: 11;
					right: 57%;
					position: absolute;
					top: 73%;
				}
			}					
			
            img {
                width: 100%;
				margin-top: -50px;
				@media (max-width: 992px) {
					margin-top: 0;
				}
            }
			
            @media (max-width: 1460px) {
                width: 600px;
            }

            @media (max-width: 1250px) {
                width: 500px;
            }

            @media (max-width: 1199px) {
                width: 500px;
            }
            @media (max-width: 991px) {
				width: 100%;
				position: absolute;
				top: 304px;
				right: 0;
				display: block;				
            }
            @media (max-width: 640px) {
				top: 339px;
            }			


        }
    }

    .banner-second {
        display: flex;
        align-items: center;
        position: relative;
		margin-top: 12%;
		padding: 0 10% 0 10%;
		margin-top: 9%;
		@media (max-width: 992px) {
			margin-top: 93%;
		}
        .description {
            font-size: 20px;
			/*margin-left: 40%;*/
			z-index: 10;
			&.padd-50 {
				margin-left: 6%;
			}
			@media (max-width: 992px) {
				.the-desc-small {
					font-size: 1.1rem;
				}			
			}			
		@media (max-width: 992px) {
			/*margin-top: 290px;*/
			margin-top: auto;
		}
        }		
		
        .image-banner {
            position: relative;
            left: 30px;
			width: 29%;
            @media (max-width: 991px) {
				width: 468px;
				position: relative;
				top: 284px;
				right: 0;
				display: block;
            }			
			.rotate {
				animation: rotation 8s infinite linear;
			}
			@keyframes rotation {
			  from {
				transform: rotate(0deg);
			  }
			  to {
				transform: rotate(359deg);
			  }
			}
 			
			.smartfon-vid {
				z-index: 10;

				@media (max-width: 992px) {
					width: 74%;
				}				

			}

			.zarowka-vid {
				width: 70px;
				z-index: 11;
				left: 56%;
				position: absolute;
				top: 21%;
				@media (max-width: 992px) {
					width: 18%;
					left: 38%;
					top: 21%;				
				}
			}			

            @media (max-width: 1460px) {
                width: 600px;
            }

            @media (max-width: 1250px) {
                width: 500px;
            }

            @media (max-width: 1199px) {
                width: 500px;
				top: 17%;
				display: none;
            }
            /*@media (max-width: 991px) {
                display: none;
            }*/


        }
    }
	
    .banner-third {
        display: flex;
        align-items: center;
        position: relative;
		padding: 5% 10%;
        .description {
            font-size: 20px;
			@media (max-width: 992px) {
				.the-desc-small {
					font-size: 1.1rem;
				}			
			}			
        }

        .image-banner {
			position: absolute;
			top: -40px;
			right: 99px;
			width: 30%;

            @media (max-width: 991px) {
				width: 468px;
				position: relative;
				top: 24px;
				right: 0;
				display: block;
				margin: 0 auto;
            }
			
			.laptop-vid {
				z-index: 10;
				width: 430px;
				margin-left: 80px;
				@media (max-width: 992px) {
					width: 100%;
					margin-left: 0;
				}
			}

			.play-vid {
				width: 70px;
				z-index: 11;
				right: 48%;
				position: absolute;
				top: 70%;
			}
			
            @media (max-width: 1460px) {
                width: 400px;
            }

            @media (max-width: 1250px) {
                width: 500px;
            }

            @media (max-width: 1199px) {
                width: 500px;
            }
            /*@media (max-width: 991px) {
                display: none;
            }*/
        }
    }	
	
    .banner-fourth {
        display: flex;
        align-items: center;
        position: relative;

        .description {
            font-size: 20px;
        }

        .image-banner {
            position: absolute;
            top: -320px;
            right: 30px;
			width: 40%;
			z-index: -1;
			
			.laptop-vid {
				z-index: 10;
			}

			.play-vid {
				width: 70px;
				z-index: 11;
				right: 48%;
				position: absolute;
				top: 70%;
			}
			
            @media (max-width: 1460px) {
                width: 600px;
            }

            @media (max-width: 1250px) {
                width: 500px;
            }

            @media (max-width: 1199px) {
                width: 500px;
            }
            /*@media (max-width: 991px) {
                display: none;
            }*/

            img {
                width: 100%;
            }
        }
    }


	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
    .banner-fifth {
        display: flex;
        align-items: center;
        position: relative;
		padding: 0 10% 0 10%;
        .description {
            font-size: 20px;
			@media (max-width: 992px) {
				.the-desc-small {
					font-size: 1.1rem;
				}			
			}
        }

        .image-banner {
            position: absolute;
            top: -40px;
            right: 30px;
			width: 40%;
			
            @media (max-width: 992px) {
                width: 500px;
				position: absolute;
				top: 282px;
				right: 0;
				display: block;
				
            }
			
			@media (max-width: 640px) {
				width: 378px;
				top: 384px;
			}
			
			.rotate {
				animation: rotation 8s infinite linear;
			}
			@keyframes rotation {
			  from {
				transform: rotate(0deg);
			  }
			  to {
				transform: rotate(359deg);
			  }
			}			
			
			.laptop-vid {

			z-index: 10;
			@media (max-width: 640px) {
				width: 100%;
				margin-top: 60px;
			}
			&.rotates {
				@media (max-width: 992px) {
					width: 100%;
				}
			}			
			}

			.play-vid {
				width: 70px;
				z-index: 11;
				right: 48%;
				position: absolute;
				top: 70%;
				
				@media (max-width: 991px) {				
					width: 39px;
					z-index: 11;
					right: 57%;
					position: absolute;
					top: 73%;
				}
			}					
			
            img {
                width: 100%;
            }
			
            @media (max-width: 1460px) {
                width: 600px;
            }

            @media (max-width: 1250px) {
                width: 500px;
            }

            @media (max-width: 1199px) {
                width: 500px;
            }
            @media (max-width: 991px) {
				width: 100%;
				position: absolute;
				top: 304px;
				right: 0;
				display: block;
            }
            @media (max-width: 640px) {
				top: 406px;
            }			


        }
    }	
	
	
	
	
	
	
	
	
	
	
	    .banner-sixth {
        display: flex;
        align-items: center;
        position: relative;
		margin-top: 9%;
		margin-bottom: 8%;

        .description {
            font-size: 20px;
        }
		
		.glutek-gorny {
			width: 100px;
		}

			.image-banner {
            position: absolute;
            top: -40px;
            right: 30px;
			width: 40%;
			
			.rotate {
				animation: rotation 8s infinite linear;
			}
			@keyframes rotation {
			  from {
				transform: rotate(0deg);
			  }
			  to {
				transform: rotate(359deg);
			  }
			}			
			
			.laptop-vid {
				z-index: 10;
				margin-left: 186px;
			}

			.play-vid {
				width: 27%;
				z-index: 11;
				right: 4%;
				position: absolute;
				top: 55%;
			}
			
			.play-vid-sec {
				width: 62%;
				z-index: 11;
				right: 4%;
				position: absolute;
				top: 55%;
			}			
			
			.play-dlon {
				width: 45%;
				z-index: 11;
				right: 0%;
				position: absolute;
				top: 100%;			
			}			
			
            @media (max-width: 1460px) {
                width: 600px;
            }

            @media (max-width: 1250px) {
                width: 500px;
            }

            @media (max-width: 1199px) {
                width: 500px;
            }
            /*@media (max-width: 991px) {
                display: none;
            }*/

            img {
                width: 100%;
            }
        }
    }	
	
	
	#zysk {
	text-align: center;
	background-color: #f2f2f2;
		.zysk-cions {		
			margin-top: 6%;
			.content-title {
				padding-top: 6%;
			}
			.host-content {
				text-align: center;
				font-weight: 600;
				font-size: 1.4rem;
			}
		}	
	}
	
	#zysk-second{
	text-align: center;
		.zysk-cions {
				.real-poz {
					font-size: 1.1rem!important;
				}		
			margin-top: 4%;
			@media (max-width: 992px) {
				margin-top: 64%
			}		
			.content-title {
				padding-top: 6%;
			}
			.host-content {
				text-align: center;
				font-weight: 600;
				color: #ab1929;
				font-size: 2.4rem!important;
			}
		}
		.bud-text {
			text-align: center;
			font-size: 1.1rem !important;
		}
	}	
	
	.contact-us {
	background-color: #f2f2f2;
	text-align: center;
	padding-top: 5%;
	img {
		width: 100%;
	}
	.button-write {
	@media (max-width: 992px) {
		width: 4%;
	}	
	}
	&.white-bg {
		background-color: #fff;
	}
		.d-flex{
			padding: 0!important;
			.bd-highlight {
				padding: 0!important;
				font-size: 1.2rem;
			}
		}
		.btn-price {
			background: #ab1929;
			padding: 20px 80px;
			color: #fff;
			margin: 60px 0;
			border-radius: 40px;
			font-size: 1.3rem;
			transition: .3s all ease;	
			@media (max-width: 992px) {
				padding: 20px 41px;
			}
		}
	}
	

    .pagination {
        display: flex;
        justify-content: center;
        padding: 107px 0 40px;

        .page-number {
            font-size: 26px;
            font-weight: 300;
            border-radius: 100%;
            width: 66px;
            height: 66px;
            display: flex;
            background: #C0C0C0;
            align-items: center;
            justify-content: center;
            margin: 0 1.1rem;
            color: $white;

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            background: $color-primary;
        }
    }
}

#rocket-takeoff-wrapper {
  height: 100vh;
  animation: 10s rocketTakeOff 2s forwards cubic-bezier(.97,.37,.26,.3);
}
#rocket-wrapper {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
}
#rocket {
    width: 200px;
    height: 200px;
	@media (max-width: 992px) {
		width: 207px;
		height: 140px
	}
}

@keyframes rocketTakeOff {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, -30%);
    }
}

@keyframes earthLeave {
    0% {
        transform: translatey(0);
    }
    100% {
        transform: translatey(100%);
    }
}
.thruster {
    position: relative;
    z-index: -5;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translatex(-11%);
    opacity: 0;
    animation: 500ms fadein 1s forwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
}
.flame-wrapper {
    position: relative;
    animation: flicker 600ms ease-in infinite;
}
.flame {
    bottom: 0;
    position: absolute;
    border-radius: 50% 50% 50% 0%;
    transform: rotate(-45deg);
}

.red {
    width: 45px;
    height: 45px;
    background: orangered;
    }
.orange {
    left: 5px;
    width: 35px;
    height: 35px;
    background: orange;
}
.gold {
    left: 10px;
    width: 25px;
    height: 25px;
    background: gold;
}
.white {
    left: 1.1rem;
    width: 1.1rem;
    height: 1.1rem;
    background: lightyellow;
}

@keyframes flicker {
    0% {
      transform: rotate(-1deg);
    }
    20% {
      transform: rotate(2deg) scaleY(1.2);
    }
    40% {
      transform: rotate(-1deg);
    }
    60% {
      transform: rotate(1deg);
    }
    80% {
      transform: rotate(-1deg) scaleY(0.9);
    }
    100% {
      transform: rotate(1deg);
    }
}

